import { IoFilterCircleOutline } from "react-icons/io5";

export default function FilterChoose({open, setOpen, filter, setFilter}) {

    
    const handlefilter = async (e) => {
        setFilter(e.currentTarget.id);
    }

    return(
        <ul className="list">
            <a className="item-list waves-effect" id={"f_d"} onClick={handlefilter}>
                <div className="cont-icon">
                    <IoFilterCircleOutline className="icon_item"/>
                </div>
                <span className="tex_item">FECHA DES</span>
            </a>
            <a className="item-list waves-effect" id={"manual"} onClick={handlefilter}>
                <div className="cont-icon">
                    <IoFilterCircleOutline className="icon_item"/>
                </div>
                <span className="tex_item">MANUAL</span>
            </a>
        </ul>
    )
}