import {Navigate} from 'react-router-dom'
import {useApp} from "../../context/appContext";


export default function ProtectedPublic({children}) {
	const {session, onload} = useApp();

	if (onload) return  <div className='loadInit'>
							<div className='sippiner'></div>
						</div>

	if (session) return <Navigate to="/app"/>

	return <>{children}</>
}