import { IoClose, IoAlertCircleSharp, IoCheckmarkCircle } from "react-icons/io5";
import {useState, useEffect} from 'react' 

export default function SnackBar({text, setMessage, type, close}) {
    const [animation, setAnimation] = useState('');
    const handleClosed = () => {
        setAnimation('fadeOutLeft');
        setTimeout(function() {
            setMessage(false);
        }, 300);
    }
    useEffect(() => {
        if (text) {
            if (!close) {
               setTimeout(function() {
                    handleClosed();
                }, 3000);
            }
        }
    }, [text])
    return (
        <div className={type && type == "done" ? "fixed-left-bottom p-1 slideInUp bg-primary-full "+animation : "fixed-left-bottom p-1 slideInUp bg-danger-300 "+animation}>
            <div className="row w-100 justify-between rounded-4 justify-items-center">
                {type && type == 'done' ? <IoCheckmarkCircle className="icon-message p-2"></IoCheckmarkCircle> :
                type == 'error' ? <IoAlertCircleSharp className="icon-message p-2"></IoAlertCircleSharp> :
                <IoAlertCircleSharp className="icon-message p-2"></IoAlertCircleSharp>}
                <p className={close ? "" : "sm-col sm-text-center pr-6"}>{text ? text : 'Default mensaje'}</p>
                {close ? <IoClose className="closed-button p-2 waves-effect" onClick={handleClosed}></IoClose> : ''}
            </div>
        </div>
    )
}