import {useState, useEffect, useRef} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {useNavigate} from 'react-router-dom'
import {limitText} from "../utility/utils"
import ProgressModal from "../ui/ProgressModal";
import {IoChevronBack, IoCloseCircle, IoCheckmark, IoCloudUploadOutline, IoRefreshCircle, IoSettingsSharp} from "react-icons/io5";
import Select from 'react-select'


export default function ListForm({open, setOpen, isComplete, listId, isAcc, setMessage, handleClosed}) {
    const {session, loading, setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [progress, setProgress] = useState(0);
    const [image, setImage] = useState("/images/caratula.jpg");
    const [isFailed, setFailed] = useState(true);
    const file_c_reg = useRef();
    const file_l_reg = useRef();
    const reader = new FileReader();
    const navigate = useNavigate();
    const [selectes, setSelectes] = useState([]);
    const [songs, setSongs] = useState([]);
	const [listR, setListR] = useState({
        name: "",
        file: "",
        certificate: ""
    });
    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            window.scrollTo(0,0);
            file_c_reg.current.value = '';
            file_l_reg.current.value = '';
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (listR.name != "") {
                let formData = new FormData();
                formData.append('name', listR.name);
                formData.append('file', listR.file);
                formData.append('certificate', listR.certificate);
                if (selectes.length > 0) {
                    for (let i = 0; i < selectes.length; i++) {
                        formData.append('songs[]', selectes[i]);
                    }
                }
                setLoading(true);
                setFailed(false);
                button.disabled = true;
                let Req;
                if(window.XMLHttpRequest) {
                    Req = new XMLHttpRequest();
                }
                if (listId) {
	            	Req.open("PUT", urlbase+"/app/update_list/"+listId, true);
	            }else{
	            	Req.open("POST", urlbase+"/app/save_list", true);
	            }
                Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                Req.setRequestHeader('Authorization', session.token);
                Req.responseType = 'json';
                Req.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        var ratio = Math.floor((e.loaded / e.total) * 100);
                        setProgress(ratio);
                    }
                }
                Req.upload.onerror = function (e) {
                    setFailed(true);
                    console.log("** An error occurred during the transaction");
                };
                Req.onreadystatechange = async function() {
                    if (Req.readyState === 4) {
                        const res = Req.response;
                        if(res.clave && res.clave == "exito") {
                            isComplete();
                            setMessage({text: "Lista de registro agregada con exito!", type:"done"});
                            setLoading(false);
                            button.disabled = false;
                            setOpen(false);
                            setListR({ name: "", file:""});
                            window.scrollTo(0,0);
                            setIsLoad(true);
                            file_c_reg.current.value = '';
                            file_l_reg.current.value = '';
                        }else{
                            setAlert(res.mensaje);
                            setLoading(false);
                            button.disabled = false;
                        }
                    }
                }
                Req.send(formData);
            }else{
            	setAlert("Debes agregar el nombre de la lista");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadSongs = async (id) => {
    	const res = await fetch(urlbase+"/app/get_songs_search", {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.songs;
        setSongs(data);
    }
    const loadList = async (listId) => {
    	const res = await fetch(urlbase+"/app/get_list_of_reg/"+listId, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.list;
        setListR({ name: data.name, songs:data.songs});
        if (data.songs && data.songs.length > 0) {
            setSelectes(data.songs.map((sng) => {return sng._id}));
        }
        setIsLoad(false);
        file_c_reg.current.value = '';
        file_l_reg.current.value = '';
    }
    const handleReload = (e) => {
        setProgress(0);
        handleSubmit(e);
    }
    const handleChange = (e) => {
        setListR({...listR, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleCerti = (e) => {
        if (e.target.files[0]) {
            setListR({...listR, ['certificate']: e.target.files[0]});
        }else{
            setListR({...listR, ['certificate']: null});
        }
    }
    const handleList = (e) => {
        if (e.target.files[0]) {
            setListR({...listR, ['file']: e.target.files[0]});
        }else{
            setListR({...listR, ['file']: null});
        }
    }
    const handleMulti = (e) => {
        let arr = [];
        if (e.length > 0) {
            arr = e.map(function(item) {
                return item['value'];
            });
        }else{
            arr = [];
        }
        setSelectes(arr);
        setAlert(null);
    }

    useEffect(() => {
        if (open) {
            setListR({ name: "", file:""});
            setAlert(null);
            loadSongs();
            if (listId != null){
                setIsLoad(true);
                loadList(listId);
            }else{
                setIsLoad(false);
                setImage("/images/caratula.jpg");
            }
        }
    }, [open]);

    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;

	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle">{!listId ? 'Registro' : 'Editar registro'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row gap-1 w-100">
                    <div className="row w-100">
                        <label className="lb-inp-usm w-100">
                            <div className='row w-100 justify-between'>
                                <span>Nombre</span>
                                <span className='tg-requir'>obligatorio</span>
                            </div>
                        </label>
                        <input type="text" className="inp_add text-uppercase" value={listR.name} onChange={handleChange} name="name" placeholder="LIST-3F56SR" autoComplete="off"/>
                    </div>
                    <div className="row w-100">
                        <label className="lb-inp-usm my-1">Certificado de registro (opcional)</label>
                        <input type="file" ref={file_c_reg} className="inp_add" onInput={handleCerti} name="certificate" accept=".pdf,.docx,.doc"/>
                    </div>
                    <div className="row w-100 my-1">
                        <label className="lb-inp-usm">Lista de registro ({listId ? 'Cargar otra' : 'opcional'})</label>
                        <input type="file" ref={file_l_reg} className="inp_add" onInput={handleList} name="file" accept=".pdf,.docx,.doc"/>
                    </div>
                    <div className="row w-100 justify-center py-1">
                        <label className="lb-inp-usm mb-1 w-100">
                            <div className='row w-100 justify-between'>
                                <span>Canciones en la lista</span>
                                <span className='tg-requir'>buscar</span>
                            </div>
                        </label>
                        <div className='position-relative w-100'>
                            <Select className='multi_sel'
                                name="requested"
                                placeholder={"Elegir canciones"}
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                onChange={handleMulti}
                                defaultValue={listId && listR.songs.length > 0 ? listR.songs.map((sng) => {return {value: sng._id, label:sng.title}}) : ""}
                                options={songs.map((song, ind) => {
                                    return {value:song._id, label:song.title}
                                })}
                                />
                        </div>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
            </div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{loading ? 'SUBIENDO...': listId ? 'Aplicar' : 'Agregar'}</button>
                    </div>
                </div>
            </div>
            { loading ? <div className='fixed-upload'>
                <div className='center-content'>
                    <div className="row w-100">
                        <div className='row w-100 text-center justify-center p-3'>
                            <IoCloudUploadOutline className="icon-upld-anim"/>
                        </div>
                        <div className='row w-100 p-2'></div>
                        <div className="row w-100 cont_downloaad">
                            <div className="img_inl text-center">
                                <img src={'/images/caratula.jpg'} className="img-profile-med" />
                            </div>
                            <div className="text_inl text-left">
                                <p className="tag_tt_song clear-my">SUBIENDO...</p>
                                <p className="stx_tt text-secondary sm-text clear-my mb-1">LISTA DE REGISTRO</p>
                            </div>
                            <div className="row w-100 text-center">
                                <div className='barr_gains showBarr w-100'>
                                    <div className={isFailed ? "line_progrs_int brr_red" : progress >= 100 ? "line_progrs_int brr_grren" : "line_progrs_int"} style={{width:progress+'%'}}></div>
                                </div>
                            </div>
                            {isFailed ?  <span className="isDownloafail"><IoCloseCircle className="checkfail"/></span> : progress >= 100 ? <span className="isDownloaded">
                                <IoCheckmark className="checkisdone"/>
                            </span> : ''}
                            {progress < 100 ? <span className="isDownloaded"><div className="spinner_sm"></div></span> : ''}
                        </div>
                        {isFailed ? <div className={"d-flex mt-1 w-100 justify-between justify-items-center bg-danger-light rounded-4"}>
                            <p className="text-message p-2">Error al subir los archivos, mala conexion</p>
                            <IoRefreshCircle className="reload-button p-2 waves-effect waves-light" onClick={handleReload}/>
                        </div> : ''}
                        <div className='row w-100 text-center p-3'>
                            <p className="stx_tt text-secondary sm-text clear-my mb-1">No cierres esta ventana hasta que se complete la subida de archivos</p>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </div>
	)
}