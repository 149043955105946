import { useEffect, useState, useCallback } from "react";
import Cropper from 'react-easy-crop';
import getConvertCroppedImg from '../utility/convertImage'

export default function CroppieImage({open, image, aspect, getCompleteCorp}){
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [zoom, setZoom] = useState(1);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const showCroppedImage = async () => {
        try {
            const croppedImage = await getConvertCroppedImg(image, croppedAreaPixels);
            getCompleteCorp(croppedImage);
        } catch (e) {
          console.error(e)
        }
    }

    useEffect(() => {
        showCroppedImage();
    }, [croppedAreaPixels]);

    return(
        <div className="content-croppie position-relative">
            <div className="crop-container">
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className="controls">
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value)
                  }}
                  className="zoom-range"
                />
              </div>
        </div>
    )
}