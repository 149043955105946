import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {validEmail} from "../utility/utils"
import ProgressModal from "../ui/ProgressModal";
import {IoChevronBack} from "react-icons/io5";


export default function EditoraForm({open, setOpen, isComplete, editoraId, isEmployee, refName, idSucursal, setMessage, handleClosed}) {
    const {setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [encargado, setEncargado] = useState({name: "", phone: "", whatsapp: "", email: ""});
	const [editora, setEditora] = useState({
        name: "",
        country: "",
        state: "",
        city: "",
        phone: "",
        email: "",
        direction: "",
        description: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setOpen(false);
            setAlert(null);
            window.scrollTo(0,0);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (editora.name != "" && encargado.nombre != ""){
                setLoading(true);
                button.disabled = true;
                editora.encargado = encargado;
            	var response = null;
	            if (editoraId) {
	            	response = await fetch(urlbase+"/app/update_editora/"+editoraId, {
		                method: 'PUT',
		                headers: myHeaders(),
		                body: JSON.stringify(editora)
		            })
	            }else{
	            	response = await fetch(urlbase+"/app/save_editora", {
		                method: 'POST',
		                headers: myHeaders(),
		                body: JSON.stringify(editora)
		            })
	            }
	            const res = await response.json();
	            if(res.clave && res.clave == "exito"){
                    if (editoraId) {
                        setMessage({text: "Editora actualizada", type:"done"});
                    }else{
                        setMessage({text: "Editora guardadada", type:"done"});
                    }
	                setOpen(false);
	                isComplete();
	                setEditora({name: "", country: "", state: "", city: "", phone: "", email: "", direction: "", description: ""});
                    setEncargado({name: "", phone: "", whatsapp: "", email: ""});
                    window.scrollTo(0,0);
                    setIsLoad(true);
	            }else{
                    setAlert(res.mensaje);
                }
                setLoading(false);
                button.disabled = false;
            }else{
            	setAlert("Completa los campos nombre, encargado");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadEditora = async (id) => {
    	const res = await fetch(urlbase+"/app/get_editora/"+id, {
            method: 'GET',
            headers:myHeaders()
        })
    	const response = await res.json();
    	const data = response.editora;
        setEncargado(data.encargado);
        setIsLoad(false);
    	setEditora({name:data.name, country:data.country, state:data.state, city:data.city, phone:data.phone, email:data.email, direction:data.direction, description:data.description});
    }
    const handleChange = (e) => {
        setEditora({...editora, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleEncargado = (e) => {
        setEncargado({...encargado, [e.target.name]: e.target.value});
        setAlert(null);
    }
    useEffect(() => {
        setEditora({name: "", country: "", state: "", city: "", phone: "", email: "", direction: "", description: ""});
        setEncargado({name: "", phone: "", whatsapp: "", email: ""});
        setAlert(null);
    	if (editoraId != null) {
            setIsLoad(true);
    		loadEditora(editoraId);
    	}else{
            setIsLoad(false);
        }
    }, [editoraId]);


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle">{editoraId != null ? 'Editar' : 'Nueva editora'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row">
                    <label className="lb-inp-usm mb-1">Nombre de la editora</label>
                    <input type="text" className="inp_add text-uppercase" value={editora.name} onChange={handleChange} name="name" placeholder="Nombre completo" autoComplete="off"/>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Ciudad</label>
                    <input type="text" className="inp_add text-uppercase" value={editora.city} onChange={handleChange} name="city" placeholder="Guadalajara" autoComplete="off"/>
                </div>
                <div className="row">
                    <label className="lb-inp-usm mb-1">Email editora</label>
                    <input type="text" className="inp_add" value={editora.email} onInput={validEmail} onChange={handleChange} name="email" placeholder="ejemplo@gmail.com" autoComplete="off"/>
                </div>
                <div className='row gap-2 py-2 w-100'>
                    <div className="col-1">
                        <img src={'/images/usuario.png'} className="img-profile-sm" />
                    </div>
                    <div className="col text-left">
                        <strong className="tag_inl clear-my">Encargado</strong>
                    </div>
                </div>
                <div className="row">
                    <label className="lb-inp-usm mb-1">Nombre</label>
                    <input type="text" className="inp_add" value={encargado.name} onChange={handleEncargado} name="name" placeholder="Jorge Perez *" autoComplete="off"/>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Telefonos</label>
                    <input type="text" className="inp_add" value={encargado.phone} onChange={handleEncargado} name="phone" placeholder="+52 317 655 6556 *" autoComplete="off"/>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Whatsapp</label>
                    <input type="text" className="inp_add" value={encargado.whatsapp} onChange={handleEncargado} name="whatsapp" placeholder="523176556556" autoComplete="off"/>
                </div>
                <div className="row my-1">
                    <label className="lb-inp-usm mb-1">Email</label>
                    <input type="text" className="inp_add" value={encargado.email} onInput={validEmail} onChange={handleEncargado} name="email" placeholder="ejemplo@gmail.com " autoComplete="off"/>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" onClick={handleSubmit}>{editoraId ? 'APLICAR' : 'CREAR'}</button>
                    </div>
                </div>
            </div>
        </div>
	)
}