export default function OptionMovil({open, setOpen, children, setIdClient}){
    const stopEvent = (e) => {
        e.stopPropagation();
        setOpen(false);
    }
    const handleClosed = (e) => {
        e.stopPropagation();
        setOpen(false);
        if (setIdClient) {
            setIdClient(null);
        }
    }
    return(
        <div className={open ? 'cont_option' : 'cont_option dnone'} onClick={handleClosed}>
            <div className="main_option rounded-4 slideInUp" onClick={stopEvent}>
                {children}
            </div>
        </div>
    )
}