
import {useEffect} from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd';

export default function Row({ children, song, index, moveRow, endDrag, key}) {
    const ItemType = 'ROW';

    const [{isOver}, ref] = useDrop({
      accept: ItemType,
      hover(item) {
        if (item.index !== index) {
          moveRow(item.index, index);
          item.index = index;
        }
      }, 
      drop: () => {
        // Llama a endDrag cuando el row es soltado
        endDrag();
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(), // Detecta si el row está sobre este elemento
      }),
    });
  
    const [{ isDragging }, drag, preview] = useDrag({
      type: ItemType,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    // Custom preview node
    useEffect(() => {
      if (ref.current) {
        console.log(preview);
        preview(getCustomPreview(ref.current));
      }
    }, [preview]);


    // Function to create a custom preview with white background
    const getCustomPreview = (node) => {
      const preview = node.cloneNode(true);
      preview.style.backgroundColor = 'white';
      preview.style.padding = '8px';
      preview.style.border = '1px solid #ccc';
      document.body.appendChild(preview);
      console.log(preview);
      return preview;
    };
    
  
    return (
      <tr
        ref={(node) => drag(ref(node))}
        className={isDragging ? 'dragging-row' : isOver ? 'hovered-row' : ''}
        style={{
          opacity: isDragging ? 0.5 : 1,
          transition: 'transform 0.3s ease', // Animación suave
        }} 
        key={key}>
        {children}
      </tr>
    );
  };