import LoadingBackground from './LoadingBackground'

export default function ProgressModal(){
    return(
        <div className='cont-intern-progrss w-100'>
            <div className="row w-100">
                <div className="w-100">
                    <div className="row position-relative w-100 my-2 justify-between">
                        <div className="col-2 position-relative">
                            <LoadingBackground />
                            <div className="p-3"></div>
                        </div>
                        <div className="col-2 position-relative">
                            <LoadingBackground />
                            <div className="p-3"></div>
                        </div>
                    </div>
                    <div className="row position-relative w-100">
                        <LoadingBackground />
                        <div className="p-3"></div>
                    </div>
                    <div className="row w-100 gap-2 p-2"></div>
                    <div className="row position-relative w-100 my-2">
                        <LoadingBackground />
                        <div className="p-3"></div>
                    </div>
                    <div className="row position-relative w-100 my-2">
                        <LoadingBackground />
                        <div className="p-3"></div>
                    </div>
                    <div className="row position-relative w-100 my-2">
                        <LoadingBackground />
                        <div className="p-3"></div>
                    </div>
                    <div className="row position-relative w-100 my-2">
                        <LoadingBackground />
                        <div className="p-3"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}