import MovilMenu from "../components/ui/MovilMenu";
import TrackPlayer from "../components/ui/TrackPlayer";
import Header from "../components/ui/Header";
import SideMenu from "../components/ui/SideMenu";
import ProtectedRedactor from "../components/protected/ProtectedRedactor"
import { IoHome, IoListSharp, IoPersonAddSharp, IoLayers } from "react-icons/io5";
import {useApp} from "../context/appContext";
import {Outlet} from 'react-router-dom'

export default function Redactor({children}) {
    const {playlist, setPlaylist} = useApp();
    let items = [
        {name:'Inicio', url:'/editor/tablon', icon:(<IoHome className="icon_it_he"/>)},
        {name:'Catalogo', url:'/editor/catalogo', icon:(<IoListSharp className="icon_it_he"/>)},
        {name:'Registro', url:'/editor/listas_de_registros', icon:(<IoLayers className="icon_it_he"/>)},
        {name:'Autores', url:'/editor/autores', icon:(<IoPersonAddSharp className="icon_it_he"/>)}
    ];
    let itemsM = [
        {name:'Inicio', url:'/editor/tablon', icon:(<IoHome className="icon_it_mv"/>)},
        {name:'Catalogo', url:'/editor/catalogo', icon:(<IoListSharp className="icon_it_mv"/>)},
        {name:'Registro', url:'/editor/listas_de_registros', icon:(<IoLayers className="icon_it_mv"/>)},
        {name:'Autores', url:'/editor/autores', icon:(<IoPersonAddSharp className="icon_it_mv"/>)}
    ];
    

    return(
        <>
            <ProtectedRedactor>
                <Header></Header>
                <div className="d-flex">
                    <SideMenu items={items}>
                        <div className="py-2"></div>
                    </SideMenu>
                    <main className="container_main col-md-12 col-lg-10 ms-sm-auto px-md-4 fadeIn">
                        <div className="p-2 md-p-2">
                            <Outlet />
                        </div>
                        {playlist && playlist.length > 0 ? <TrackPlayer playlist={playlist} setPlaylist={setPlaylist}/> : ''}
                    </main>
                </div>
                <MovilMenu items={itemsM}></MovilMenu>
            </ProtectedRedactor>
        </>
    )
}



        