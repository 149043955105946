import {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";

export default function SideMenu({open, items, children, animation}){
    let [elements, setElements] = useState([]);

    useEffect(() => {
        setElements(items);
    }, []);

    return(
        <div className={"fixed-right-menu bg-light col-2 "+animation} id="menu_slide">
            <div className="position-sticky pt-3">
                {children}
                <ul className="nav flex-column md-fs-11">
                    {items.map((item, ind) => {
                        return (
                            <NavLink key={'men_'+ind} to={item.url} className={({isActive}) => (isActive ? 'waves-effect nav-link isActive' : 'waves-effect nav-link')}>
                                {item.icon}
                                <span className="tex_hed_i">{item.name}</span>
                            </NavLink>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}