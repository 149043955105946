import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {IoChevronBack } from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";


export default function ClientForm({open, setOpen, isComplete, clientId, type, setMessage, handleClosed}) {
    const {setLoading, urlbase, myHeaders} = useApp();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
	const [client, setClient] = useState({
        name: "",
        phone: "",
        email: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            window.scrollTo(0,0);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (client.name != ""){
            	let response = null;
                let formData = new FormData();
                formData.append('name', client.name);
                formData.append('phone', client.phone);
                formData.append('email', client.email);
                // if (idSucursal){
                //     formData.append('branch', idSucursal);
                // }
                setLoading(true);
                button.disabled = true;
	            if (clientId) {
	            	response = await fetch(urlbase+"/app/update_client/"+clientId, {
		                method: 'PUT',
		                headers: myHeaders(true),
		                body: formData
		            })
	            }else{
	            	response = await fetch(urlbase+"/app/save_client", {
		                method: 'POST',
		                headers: myHeaders(true),
		                body: formData
		            })
	            }
	            const res = await response.json();
	            if(res.clave && res.clave == "exito"){
                    if (clientId) {
                        setMessage({text: type+" actualizado", type:"done"});
                    }else{
                        setMessage({text: type+" guardado", type:"done"});
                    }
	                setOpen(false);
	                isComplete();
	                setClient({ name: "", phone: "", email: ""});
                    window.scrollTo(0,0);
                    setIsLoad(true);
	            }else{
                    setAlert(res.mensaje);
                }
                setLoading(false);
                button.disabled = false;
            }else{
            	setAlert("Completa al menos nombre");
            }
        } catch (err) {
            console.log(err);
            setAlert("Error en el servidor");
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadClient = async (id) => {
    	const res = await fetch(urlbase+"/app/get_client/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.client;
        setClient({ name: data.name, phone: data.phone, email: data.email});
        setIsLoad(false);
    }
    const handleChange = (e) => {
        setClient({...client, [e.target.name]: e.target.value});
        setAlert(null);
    }
    

    useEffect(() => {
        if (open) {
            setClient({ name: "", phone: "", email: "" });
            setAlert(null);
            if (clientId != null){
                setIsLoad(true);
                setTimeout(() => {
                    loadClient(clientId);
                }, 300);
            }else{
                setIsLoad(false);
            }
        }
    }, [open]);

    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;

	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>{clientId != null ? 'Editar cliente' : 'Nuevo cliente'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row w-100">
                    <div className="row w-100 my-2">
                        <div className="row gap-1 w-100">
                            <label className="lb-inp-usm">Nombre completo</label>
                            <input type="text" className="inp_add text-uppercase" value={client.name} onChange={handleChange} name="name" placeholder="Nombre *" autoComplete="off"/>
                        </div>
                        <div className="row gap-1 w-100 my-1">
                            <label className="lb-inp-usm">Telefono</label>
                            <input type="text" className="inp_add" value={client.phone} onChange={handleChange} name="phone" placeholder="Telefono" autoComplete="off"/>
                        </div>
                        <div className="row gap-1 w-100">
                            <label className="lb-inp-usm">Correo (opcional)</label>
                            <input type="text" className="inp_add" value={client.email} onChange={handleChange} name="email" placeholder="Correo" autoComplete="off"/>
                        </div>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{clientId ? 'APLICAR' : 'CREAR'}</button>
                    </div>
                </div>
            </div>
        </div>
	)
}