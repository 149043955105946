import {useEffect, createContext, useContext, useState} from 'react'
import ProgressBar from "../components/ui/ProgressBar";

export const appContext = createContext();
export const useApp = () => {
	const context = useContext(appContext);
	return context;
}

export function AppProvider ({children}) {
	const [onload, setOnLoad] = useState(true);
	const [loading, setLoading] = useState(false);
	const [urlbase, setUrlBase] = useState(null);
	const [session, setSession] = useState(null);
	const [playlist, setPlaylist] = useState([]);
	const [position, setPosition] = useState(1);
	const [play, setPlay] = useState(false);

	const loadSessionToken = (auth) => {
		if (auth && auth.token) {
			localStorage.setItem('session', JSON.stringify(auth));
			setSession(auth);
			return auth;
		}else{
			let ses = JSON.parse(localStorage.getItem('session'));
			if (ses) {
				setSession(ses);
				return ses;
			}else{
				setSession(null);
				return ses;
			}
		}
	}
	const clearSession = () => {
		localStorage.removeItem("session");
		setSession(null);
	}
	const myHeaders = (file) => {
		let headers;
		if (file) {
			headers = {
				'Access-Control-Allow-Origin': '*',
	            'Authorization': ""+session.token
			}
		}else{
			headers = {
				'Access-Control-Allow-Origin': '*',
	            'Content-Type': "application/json",
	            'Authorization': ""+session.token
			}
		}
		return headers;
	}


	useEffect(() => {
		setOnLoad(false);
		setUrlBase(window.location.protocol+"//"+window.location.hostname);
		loadSessionToken();
	}, [])

	return (
		<appContext.Provider value={{onload, loading, setLoading, urlbase, loadSessionToken, session, myHeaders, clearSession, play, setPlay, playlist, setPlaylist, setPosition, position}}>
			{loading ? (<div className="fixed-top-barr"><ProgressBar></ProgressBar></div>) : ''}
			{children}
		</appContext.Provider>
	)
}