import { useState } from "react";
import { IoClose } from "react-icons/io5";

export default function Modal({open, setOpen, size, height, children, handleClosed}){
    const [present, setPresent ] = useState("slideInUp");

    const stopEvent = (e) => {
        e.stopPropagation();
    }
    const waitClosed = (e) => {
        e.stopPropagation();
        setPresent("slideOutDown-edit");
        setTimeout(() => {
            setPresent("slideInUp");
            handleClosed();
        }, 400);
    }
    
    return( <>
        {open ? <div className={open ? 'cont_modal' : 'cont_modal dnone'} id="modal_opaci" onClick={waitClosed}>
            <div className={'main_modal '+ present +" "+ size + " "+ height } id="main_modal" onClick={stopEvent}>
                <IoClose className="cerrar_modal" onClick={waitClosed}/>
                {children}
            </div>
        </div> : ''}
    </>
    )
}