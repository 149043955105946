import {Link} from 'react-router-dom'
import {IoMusicalNoteOutline, IoPersonAddSharp, IoPerson, IoReceipt, IoChevronForwardOutline, IoPersonCircleSharp, IoLayers } from "react-icons/io5";
import {useApp} from "../../context/appContext";

export default function MoreOptions(){
    const {setLoading, urlbase, myHeaders} = useApp();

    return(
        <div className="Isexpand">
            <div className="limpio w-100">
                <div className="sm-my-3 w-100"></div>
                <div className="row w-100 mb-3 justify-between">
                    <div className="wrapper-1 w-100 px-2">
                        <Link className={'waves-effect link-tab'} to="/app/enviadas">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoMusicalNoteOutline className="icon-md icn-mov align-icon text-left"/> 
                                    <span className='txt_tab'>Enviadas</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/artistas">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoPersonCircleSharp className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>Artistas</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/autores">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoPersonAddSharp className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>Autores</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/listas_de_registros">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoLayers className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>Registro</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/usuarios">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoPerson className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>Usuarios</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/editoras">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoReceipt className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>Editoras</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}