import {Navigate} from 'react-router-dom'
import {useApp} from "../../context/appContext";


export default function ProtectedRedactor({children}) {
	const {session, onload} = useApp();

	if (onload) return  <div className='loadInit'>
							<div className='sippiner'></div>
						</div>

	if (!session) return <Navigate to="/"/>

	return <>{children}</>
}