import {useState, useEffect} from 'react'
import {useApp} from "../../context/appContext";
import {IoChevronBack, IoReaderSharp, IoCheckmarkCircleSharp } from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";
import {extractHour, extractDateR} from "../../components/utility/utils";

export default function List({listId, setOpen, handleClosed}){
    const {urlbase, myHeaders} = useApp();
    const [isload, setIsLoad] = useState(true);
    const [list, setList] = useState({
        name: "",
        file: "",
        certificate: "",
        songs: []
    });

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setOpen(false);
            setIsLoad(true);
        }, 200);
    }
    const loadList = async (id) => {
        const res = await fetch(urlbase+"/app/get_list_of_reg/"+id, {
            method: 'GET',
            headers:myHeaders()
        })
        const response = await res.json();
    	const data = response.list;
        setList({ name: data.name, songs:data.songs, modified:data.modified, created:data.created, file:data.file, certificate:data.certificate });
        setIsLoad(false);
    }

    useEffect(() => {
        if (listId) {
            loadList(listId);
        }
    }, [listId]);


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


    return(
        <div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>Registro</h5>
            </div>
            <div className='modal_body'>
                <div className="row w-100">
                    <div className="row justify-center w-100">
                        <div className='w-100 sm-py-2'></div>
                        <div className='w-100 sm-py-2'></div>
                        <div className="w-100 text-center">
                        <div className='row w-100'>
                                <div className="list-ficha w-100 px-1 md-p-2 text-left">
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Nombre</strong>
                                        <span className="col text-right is_link">{list.name}</span>
                                    </p>
                                    <p className="row w-100 justify-between my-1">
                                        <strong className="col sm-mov-85">Modificada</strong>
                                        <span className="col text-right text-secondary">{(extractDateR(list.modified))} {extractHour(list.modified)}</span>
                                    </p>
                                    <p className="row w-100 justify-between">
                                        <strong className="col sm-mov-85">Canciones</strong>
                                        <span className="col text-right is_link text-secondary">{list.songs.length}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='row w-100 my-1'>
                            <label className="lb-inp-usm mb-1">Certificado: </label>
                            {list.certificate && list.certificate != '' ? <a className="tag-phone tag-phone-mv w-100" href={urlbase+list.certificate} target="_blank">
                                <IoReaderSharp  className="icon-phone-tg mr-1"/>
                                <span className="phone-number is_link sm-py-3">Descargar certificado</span>
                            </a> : <span className="sm-text sm-py-3">No existe el certificado de registro</span>}
                        </div>
                        <div className='row w-100'>
                            <label className="lb-inp-usm mb-1">Documento lista: </label>
                            {list.file && list.file != '' ? <a className="tag-phone tag-phone-mv w-100" href={urlbase+list.file} target="_blank">
                                <IoReaderSharp  className="icon-phone-tg mr-1"/>
                                <span className="phone-number is_link sm-py-3">Descargar lista de registro</span>
                            </a> : <span className="sm-text sm-py-3">No existe el documento de lista de registro</span>}
                        </div>
                        <div className='row gap-2 py-2 w-100'>
                            <div className="col-1">
                                <img src={'/images/logo_iso.png'} className="img-profile-sm" />
                            </div>
                            <div className="col text-left">
                                <strong className="tag_inl clear-my">Canciones registradas</strong>
                            </div>
                        </div>
                        <div className='row w-100'>
                            {list.songs.map((song) => {
                                return <a className="tag-phone f_imp row justify-between justify-items-center w-100 mb-1">
                                    <div className="col sm-col-12">
                                        <img src={'/images/caratula.jpg'} className="img-profile-sm sm-img-med mt-p3 mr-1" />
                                        <p className="tag_inl inl_bk_sm clear-my">
                                            <div className='is_link'>{song.title}</div>
                                            <div className='sm-text-75 text-secondary'>{(extractDateR(list.created))} {extractHour(list.created)}</div>
                                            <strong className='sm-text-75'>
                                                <span className='tg-state-cre'>
                                                    <IoCheckmarkCircleSharp  className="icn-sate" />
                                                    {song.state}
                                                </span>
                                            </strong>
                                        </p>
                                    </div>
                                </a>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-100 py-3'></div>
        </div>
    )
}