const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
const años = ["2021", "2022", "2023", "2024", "2025", "2026", "2027"];
const semana = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
//convierte cifras español
var o=new Array("diez", "once", "doce", "trece", "catorce", "quince", "dieciséis", "diecisiete", "dieciocho", "diecinueve", "veinte", "veintiuno", "veintidós", "veintitrés", "veinticuatro", "veinticinco", "veintiséis", "veintisiete", "veintiocho", "veintinueve");
var u=new Array("cero", "uno", "dos", "tres", "cuatro", "cinco", "seis", "siete", "ocho", "nueve");
var d=new Array("", "", "", "treinta", "cuarenta", "cincuenta", "sesenta", "setenta", "ochenta", "noventa");
var c=new Array("", "ciento", "doscientos", "trescientos", "cuatrocientos", "quinientos", "seiscientos", "setecientos", "ochocientos", "novecientos");


const validPhone = (e) => {
	let input = e.target;
	let x = input.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
	input.value = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
}
const extractFormatDate = (date) => {
    if (date != "" && date != null) {
        var newDate = new Date(date);
        var dia = newDate.getUTCDate();
        return addZero(dia)+" "+meses[newDate.getUTCMonth()];
    }else{
        return "";
    }
}
const reformatDate = (date) => {
    if (date != "" && date != null) {
        var tx = date.split("-");
        return tx[0]+"/"+tx[1]+"/"+tx[2];
    }else{
        return "";
    }
}
const extractFormDate = (date) => {
    if (date != "" && date != null) {
        var newDate = new Date(date);
        var año = newDate.getUTCFullYear();
        var mes = newDate.getUTCMonth();
        var dia = newDate.getUTCDate();
        return addZero(dia)+" "+meses[mes]+" "+año;
    }else{
        return "";
    }
} 
const extractDate = (date) => {
	if (date != "" && date != null) {
        var newDate = new Date(date);
        var año = newDate.getUTCFullYear();
        var mes = newDate.getUTCMonth();
        var dia = newDate.getUTCDate();
        return año+"-"+addZero(mes)+"-"+addZero(dia);
    }else{
        return "";
    }
}
const extractDateFormatR = (date) => {
    if (date != "" && date != null) {
        var newDate = new Date(date);
        var año = newDate.getUTCFullYear();
        var mes = newDate.getUTCMonth();
        var dia = newDate.getUTCDate();
        return addZero(dia)+" "+meses[mes].toUpperCase()+", "+año;
    }else{
        return "";
    }
}
const extractDateR = (date) => {
    if (date != "" && date != null) {
        var newDate = new Date(date);
        var año = newDate.getUTCFullYear();
        var mes = newDate.getUTCMonth();
        var dia = newDate.getUTCDate();
        return addZero(dia)+"-"+addZero(mes)+"-"+año;
    }else{
        return "";
    }
}
const extractHour = (date) => {
    if (date != "") {
        var date = new Date(date);
        return tConvert(addZero(date.getHours())+":"+addZero(date.getMinutes()));
    }else{
        return "";
    }
}
const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}
const addZero = (i) => {
    if (i < 10) {
        i = '0' + i;
    }
    return i;
}
const limitText = (txt, num) => {
    if (txt && txt.length > 0) {
        if (txt.length > num) {
           return txt.substr(0, num)+"...";
        }else{
            return txt;
        }
    }else{
        return "";
    }
}
const timeDifference = (current, previous) => {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = current - previous;
    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + 's';   
    }
    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + 'm'; 
    }
    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + 'h';   
    }
    else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + 'd';
    }
    else if (elapsed < msPerYear) {
        var meses = Math.round(elapsed/msPerMonth);
        if (meses == 1) {
            return  meses + ' mes';
        }else{
            return  meses + ' meses';
        }
    }
    else {
        var años = Math.round(elapsed/msPerYear);
        if (años == 1) {
            return  años + ' año';
        }else{
            return  años + ' años';
        }
    }
}
const searchTable = (tabla, texto) => {
    var tableReg = tabla;
    var searchText = texto.toLowerCase();
    var total = 0;
    for (var i = 0; i < tableReg.rows.length; i++) {
        if (tableReg.rows[i].classList.contains("noSearch")) {
            continue;
        }
        var found = false;
        var cellsOfRow = tableReg.rows[i].getElementsByTagName('td');
         // Recorremos todas las celdas
        for (var j = 0; j < cellsOfRow.length && !found; j++) {
            var compareWith = cellsOfRow[j].innerHTML.toLowerCase();
            // Buscamos el texto en el contenido de la celda
            if (searchText.length == 0 || compareWith.indexOf(searchText) > -1) {
                found = true;
                total++;
            }
        }
        if (found) {
            tableReg.rows[i].style.display = '';
        } else {
            // si no ha encontrado ninguna coincidencia, esconde la
            // fila de la tabla
            tableReg.rows[i].style.display = 'none';
        }
    }
}
function aLetras(n){
  var n=parseFloat(n).toFixed(2); /*se limita a dos decimales, no sabía que existía toFixed() :)*/
  var p=n.toString().substring(n.toString().indexOf(".")+1); /*decimales*/
  var m=n.toString().substring(0,n.toString().indexOf(".")); /*número sin decimales*/
  var m=parseFloat(m).toString().split("").reverse(); /*tampoco que reverse() existía :D*/
  var t="";
  for (var i=0; i<m.length; i+=3)
  {
    var x=t;
    var b=m[i+1]!=undefined?parseFloat(m[i+1].toString()+m[i].toString()):parseFloat(m[i].toString());
    t=m[i+2]!=undefined?(c[m[i+2]]+" "):"";
    t+=b<10?u[b]:(b<30?o[b-10]:(d[m[i+1]]+(m[i]=='0'?"":(" y "+u[m[i]]))));
    t=t=="ciento cero"?"cien":t;
    if (2<i&&i<6)
      t=t=="uno"?"mil ":(t.replace("uno","un")+" mil ");
    if (5<i&&i<9)
      t=t=="uno"?"un millón ":(t.replace("uno","un")+" millones ");
    t+=x;
  }
  t=t.replace("  "," ");
  t=t.replace(" cero","");
  return t;
}
function separarImagen(blob) {
    var img_b64 = blob; //base64 de la imagen
    var png = img_b64.split(',')[1];
    var binary = fixBinary(window.atob(png));// <-- Usamos la fn "fixBinary"
    var the_file = new Blob([binary], {type: 'image/png'});// <-- Sacamos el encode
    var imagen_firma = new File([the_file], 'image_procesing.png', { type: 'image/png' });
    return imagen_firma;
}
function fixBinary (bin) {
    var length = bin.length;
    var buf = new ArrayBuffer(length);
    var arr = new Uint8Array(buf);
    for (var i = 0; i < length; i++) {
    arr[i] = bin.charCodeAt(i);
    }
    return buf;
}
const fNumber = (val) => {
    if (val && val != "") {
        return (Number(val)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }else{
        return "0.00";
    }
}


module.exports = {
    meses,
    años,
    reformatDate,
    extractFormatDate,
    extractFormDate,
	validPhone,
	extractDate,
    aLetras,
    semana,
    limitText,
    separarImagen,
    fNumber,
    timeDifference,
    extractDateR,
    extractDateFormatR,
    extractHour,
    searchTable
}