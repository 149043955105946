import Apartadas from './Apartadas';
import Libres from './Libres';
import Grabadas from './Grabadas';
import {Link} from 'react-router-dom'
import {IoMusicalNoteOutline, IoMusicalNoteSharp, IoMusicalNotes } from "react-icons/io5";
import {useApp} from "../../context/appContext";

export default function Catalogo({type}){
    const {session, setLoading, urlbase, myHeaders} = useApp();

    return(
        <div className="fadeIn">
            <div className="px-3">
                <div className="row justify-between sm-py-3">
                    <div className="col-3 ocultar-sm">
                        <h1 className="title-lg my-1">Catalogo</h1>
                    </div>
                </div>
                <div className="sm-my-3 w-100"></div>
                <div className="row w-100 mb-3 justify-between">
                    <ul className="list">
                        <li className="item-tab">
                            <Link className={type == "Libres" ? 'waves-effect link-tab act-tab' : 'waves-effect link-tab'} to="/app/catalogo/libres">
                                <IoMusicalNoteOutline className="icon-md icn-mov align-icon"/> <span className='txt_tab'>Libres</span>
                            </Link>
                            <Link 
                                className={type == "Apartadas" ? 'waves-effect link-tab act-tab mx-1' : 'waves-effect link-tab mx-1'}
                                to={session && session.user.rol != 'Redactor' ? "/app/catalogo/apartadas" : "/editor/catalogo/apartadas"}>
                                    <IoMusicalNoteSharp className="icon-md icn-mov align-icon"/> <span className='txt_tab'>Apartadas</span>
                            </Link>
                            <Link 
                                className={type == "Vendidas" ? 'waves-effect link-tab act-tab' : 'waves-effect link-tab'} 
                                to={session && session.user.rol != 'Redactor' ? "/app/catalogo/vendidas" : "/editor/catalogo/vendidas"}>
                                    <IoMusicalNotes className="icon-md icn-mov align-icon"/> <span className='txt_tab'>Grabadas</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row w-100">
                {type == 'Libres' ? <Libres /> : type == 'Apartadas' ? <Apartadas /> : <Grabadas />}
            </div>
        </div>
    )
}