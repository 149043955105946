import {IoChevronDown, IoImage } from "react-icons/io5";

export default function AccordionEditHead({titulo, icon}) {
    return (
        <div className="row w-100 justify-between justify-items-center">
            <div>
                <div className="row w-100 justify-items-center">
                    {icon ? icon : <IoImage className='icon-md icn_chevron mr-1' />}
                    <p className='text-sm'>{titulo}</p>
                </div>
            </div>
            <IoChevronDown className='icon-md icn_chevron' />
        </div>
    )
}