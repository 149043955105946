import { IoClose, IoAlertCircleSharp } from "react-icons/io5";
import {useState} from 'react' 

export default function AlertDialog({mensaje, setMensaje}) {
    const [animation, setAnimation] = useState('fadeInDown');
    const handleClosed = () => {
        setAnimation('fadeOut');
        setTimeout(function() {
            setMensaje(null);
        }, 400);
    }
    return (
        <div className={"d-flex w-100 justify-between justify-items-center bg-danger-light rounded-4 "+animation}>
            <IoAlertCircleSharp className="icon-message p-2"></IoAlertCircleSharp>
            <p className="text-message p-1">{mensaje ? mensaje : 'Default mensaje'}</p>
            <IoClose className="closed-button p-2 waves-effect" onClick={handleClosed}></IoClose>
        </div>
    )
}